import { css } from 'lit';

const AdeleCollectionsSidebarStyles = css`
  :host {
    display: block;
    color: white;
    font-family: 'Nunito Sans';
  }

  :host::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
  }

  .container {
    box-sizing: border-box;
    background-color: rgba(28, 31, 40);
    width: 502px;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    overflow-x: hidden;
    border-radius: 0 16px 16px 0;
    backdrop-filter: blur(4px);
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
  }

  .topbar {
    padding: 24px 0 0 24px;
  }

  .content {
    padding: 0 36px 36px;
  }

  .header h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .collection-container {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .collection-container.active {
    background-color: rgba(255, 255, 255, 0.19);
  }

  .collection-name {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
  }

  .collection-threads {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
  }

  nn-button {
    height: 35px;
  }

  nn-icon {
    height: 16px;
    width: 12px;
    cursor: pointer;
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: 1rem;
  }

  .footer {
    margin-top: 1rem;
  }

  .search__input {
    outline: transparent;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .search__input {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);

    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    height: 62px;
    font-size: 16px;
    color: #fff;
    backdrop-filter: blur(4px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
  }

  .search__input::placeholder {
    color: #fff;
    opacity: 60%;
  }

  .search__input-wrap {
    position: relative;
  }

  .search__input--description {
    height: 200px;
    width: 100%;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.1);

    padding-top: 25px; /* This will prevent the user from resizing the textarea */
  }
`;

export { AdeleCollectionsSidebarStyles };
