import { LitElement, html, render } from 'lit';
import { classMap } from 'lit-html/directives/class-map.js';
import { property } from 'lit/decorators.js';

// Store
import { hasura, hasuraQueries } from '../../../../modules/hasura';

// Icons
import { xmark } from '../../../../assets/icons/xmark';

// Components
import '@mch/nn-web-viz/dist/nn-spinner';
import { Notification } from '@vaadin/notification';
import '@vaadin/button';
import '@vaadin/icons';
import '@vaadin/horizontal-layout';

import {
  registerStyles,
  css as vaadinCss,
} from '@vaadin/vaadin-themable-mixin';

// Styles
import { BaseStyles } from '../../../../assets/styles/baseStyles';
import { AdeleCollectionsSidebarStyles } from './AdeleCollectionsSidebarStyles';

registerStyles(
  'vaadin-notification-card',
  vaadinCss`
    [part='overlay'] {
    padding: 16px 19px;
    border-radius: 16px;
    background: rgba(28, 31, 40);
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    color: #ffffff;
    font-weight: 800;
    }
    [part='content'] {
      background: transparent;
    }
  `
);

class AdeleCollectionsSidebar extends LitElement {
  @property({ type: Array }) collections: Array<any> = [];
  @property({ type: Boolean }) loadingCollections: boolean = false;
  @property({ type: String }) collectionName: string = '';
  @property({ type: String }) collectionDescription: string = '';
  @property({ type: Number }) threadId: number | null = null;
  @property({ type: Boolean }) createCollectionMode: boolean = false;
  @property({ type: Boolean }) createModeOnly: boolean = false;
  @property({ type: Number }) _selectedCollectionId: number = -1;

  static styles = [BaseStyles, AdeleCollectionsSidebarStyles];

  constructor() {
    super();

    this._getCollections();
  }

  async _getCollections() {
    this.loadingCollections = true;
    const results = await hasura.query(hasuraQueries.getCollections());

    this.collections = results.collection;
    this.loadingCollections = false;
  }

  _showNotification(type, message, details) {
    const icon = type === 'Success' ? 'vaadin:check-circle' : 'vaadin:warning';
    const theme = type === 'Success' ? 'success' : 'error';

    const notification = Notification.show(
      html`
        <vaadin-horizontal-layout
          theme="spacing"
          style="align-items: center; color: white;"
        >
          <vaadin-icon icon="${icon}" style="color: white;"></vaadin-icon>
          <div style="margin-left: 10px;">
            <b>${message}</b>
            <div style="font-size: var(--lumo-font-size-s);">${details}</div>
          </div>
          <vaadin-button
            theme="tertiary-inline"
            @click="${() => notification.close()}"
            aria-label="Close"
            style="margin-left: auto; color: white;"
          >
            <vaadin-icon icon="lumo:cross"></vaadin-icon>
          </vaadin-button>
        </vaadin-horizontal-layout>
      `,
      {
        position: 'bottom-end',
        duration: 10000,
      }
    );
  }

  async _createCollection() {
    const result = await hasura.query(
      hasuraQueries.createCollection({
        name: this.collectionName,
        description: this.collectionDescription,
      })
    );

    if (result?.insert_collection?.returning.length > 0) {
      this._selectedCollectionId = result.insert_collection.returning[0].id;
      this._showNotification(
        'Success',
        'Success',
        'Collection created successfully!'
      );
      this._saveThreadToCollection();
      this._dispatchEvent('close-collections-sidebar');
    } else {
      this._showNotification('Error', 'Error', 'Failed to create collection');
    }
  }

  _dispatchEvent(eventName) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        composed: true,
        detail: { data: this._selectedCollectionId },
      })
    );
  }

  async _saveThreadToCollection() {
    if (this.threadId === null) return;

    const result = await hasura.query(
      hasuraQueries.addThreadToCollection({
        collectionId: this._selectedCollectionId,
        threadId: this.threadId,
      })
    );

    if (result?.insert_thread_collection?.affected_rows > 0) {
      this._showNotification(
        'Success',
        'Success',
        'Thread added to collection successfully!'
      );
    } else {
      this._showNotification(
        'Error',
        'Error',
        'Failed to add thread to collection'
      );
    }
  }

  _collectionNameChanged(e) {
    this.collectionName = e.target.value;
  }

  _collectionDescriptionChanged(e) {
    this.collectionDescription = e.target.value;
  }

  _renderCreateNewCollection() {
    return html`
      <div class="container">
        <div class="topbar">
          <nn-icon
            .svg=${xmark}
            @click=${() => this._dispatchEvent('close-collections-sidebar')}
          ></nn-icon>
        </div>
        <div class="content">
          <div class="header">
            <h1>Create a new collection</h1>
            <h3>Organize and group your threads in a private collection</h3>
          </div>
          <div class="body">
            <label for="title">Title</label>
            <input
              class="search__input"
              id="title"
              type="text"
              name="search"
              placeholder="Enter a title"
              @input=${this._collectionNameChanged}
            />
            <label for="description">Description (optional)</label>
            <textarea
              class="search__input search__input--description"
              id="description"
              name="search"
              placeholder="Enter a description"
              @input=${this._collectionDescriptionChanged}
            ></textarea>
          </div>
          <div class="footer flex justify-end">
            <nn-button
              outlined
              inner-color="#111322"
              @click=${() => this._setCreateCollectionMode(false)}
              >CANCEL</nn-button
            >
            <nn-button
              @click=${this._createCollection}
              ?disabled=${this.collectionName === ''}
              >CREATE</nn-button
            >
          </div>
        </div>
      </div>
    `;
  }

  _setSelectedCollection(value) {
    this._selectedCollectionId = value;
  }

  _renderCollections() {
    return html`
      ${this.collections.map(collection => {
        const count = collection.thread_collections.length;

        return html`<div
          class="collection-container ${classMap({
            active: collection.id === this._selectedCollectionId,
          })}"
          @click=${() => this._setSelectedCollection(collection.id)}
        >
          <div class="collection-name">${collection.name}</div>
          <div class="collection-threads">
            ${count} ${count > 1 ? 'threads' : 'thread'}
          </div>
        </div>`;
      })}
    `;
  }

  _setCreateCollectionMode(value) {
    if (this.createModeOnly) {
      this._dispatchEvent('close-collections-sidebar');
    }

    this.createCollectionMode = value;
  }

  _doneButtonClicked() {
    this._saveThreadToCollection();

    this._dispatchEvent('close-collections-sidebar');
  }

  _renderShowCollections() {
    return html`
      <div class="container">
        <div class="topbar">
          <nn-icon
            .svg=${xmark}
            @click=${() => this._dispatchEvent('close-collections-sidebar')}
          ></nn-icon>
        </div>
        <div class="content">
          <div class="header">
            <h1>Add to collection</h1>
            <h3>Organize and group your threads in a private collection</h3>
          </div>
          <div class="body">
            ${this.loadingCollections
              ? html`<nn-spinner theme="default"></nn-spinner>`
              : this._renderCollections()}
          </div>
          <div class="footer flex justify-between">
            <nn-button
              outlined
              inner-color="#111322"
              @click=${() => this._setCreateCollectionMode(true)}
              >NEW COLLECTION</nn-button
            >
            <nn-button @click=${this._doneButtonClicked}>DONE</nn-button>
          </div>
        </div>
      </div>
    `;
  }

  render() {
    return this.createCollectionMode
      ? this._renderCreateNewCollection()
      : this._renderShowCollections();
  }
}

export { AdeleCollectionsSidebar };
